// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// now jquery moved from config/webpack/environment.js to here
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;


import 'bootstrap'
// import 'more/sorttable'
import 'more/charges_list'
import 'more/filter_table'
import 'more/ajax'
// import 'more/treeview'
import 'css/common'
import 'css/print'
import 'css/screen'
import 'css/treeview'
import 'css/cookie_acceptance'


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// must come after turbolinks
import Cookies from 'js-cookie';
window.Cookies = Cookies;
// import "more/gems.js.erb"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
