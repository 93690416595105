import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

$(document).on('turbolinks:load', function(){
  /* initial positions */
//  if( $('.link-to-charge').length == 1) {
//    $('div.charge-info').removeClass('display-none');
//    $('.link-to-charge-p').find('span.triangle-right').hide();
//    $('.link-to-charge-p').find('span.triangle-down').show();
//  } else {
//    $('.link-to-charge-p').find('.triangle-down').hide();
//  }

    // default for all triangles on the page
    $('.link-to-charge-p').find('span.triangle-right').show();
    $('.link-to-charge-p').find('span.triangle-down').hide();
    // expand the first item on the page
    $('div.charge-info-0').removeClass('display-none');
    $('.link-to-charge-p-0').find('span.triangle-right').hide();
    $('.link-to-charge-p-0').find('span.triangle-down').show();

  $('.link-to-charge').on('click', function(e) {
    var myid = $(this).attr('data-id');
    e.preventDefault();
    $('div#charge-info-' + myid).toggle(300);
    $(this).closest('p').find('span').toggle();
  })
})
