// source https://www.c-sharpcorner.com/article/how-to-filter-data-from-html-table-using-jquery/

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

$(document).on('turbolinks:load', function(){
  //Filter table
  $("#filter-input").on("keyup", function () {
      var val = $(this).val().toLowerCase();
      $(".filterable tr").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(val) > -1)
      });
  });
  //Filter library documents
  $("#taglist .badge-tag").on("click", function () {
    $(this).toggleClass('unselected');
    // collect data-id for selected badges
    var selected_tagids = [];
    $('#taglist span:not(.unselected)').each(function () {
      selected_tagids.push( $(this).data('id') );
    });
    // if no tags are selected, show all
    if(selected_tagids.length === 0) {
      $('#librarydocs-list-div .doc').show();
      return false;
    }
    $("#librarydocs-list-div .doc").each(function () {
      var item = $(this);
      // hide all items, then show selected ones
      item.hide();
      var item_tagids = item.data('tagids');
      selected_tagids.forEach(function (i) {
        // show by tag id
        if(item_tagids && String(item_tagids).split(' ').includes(String(i))) {
          item.show();
        }
        // show by asso-name
        var item_asso = item.data('asso')
        if(item_asso && String(item_asso) == String(i)) {
          item.show();
        }
      });
      // debug code samples
      // (typeof tagids === 'string' || tagids instanceof String)
      // Number.isIngeger(tagid)
      // alert(JSON.stringify(object));
    });
  });

});

