import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

$(document).on('turbolinks:load', function(){
  $('form .submit-on-change').on( "change", function() {
    var url    = $(this).data("url");
    var myForm = $(this).parent('form');
    $.ajax({
      type: "POST",
      url : url,
      data: myForm.serialize(), // serializes the form's elements.
      success: function(data)
      {
        // alert('Posting!');
        //     $('.navigation').load('/application_controller/login');
        //  e.preventDefault();
        return;
      }
    });

  });
});

